import { post } from 'redux-bees';

const communicationChainsV3Endpoints = apiPath => ({
  getCommunicationChains: {
    method: post,
    path: `${apiPath}/communication_chains`
  }
});

export default communicationChainsV3Endpoints;
