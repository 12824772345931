import { Tooltip } from 'antd';
import React from 'react';
import SText from '../../components/Standard/SText';

export const getChainNumber = (value, color = null, placement = 'right') => {
  if (!value) {
    return '';
  }

  const string = value.trim();
  const currentLength = string.length; // Длина переданной строки
  const maxLength = 15; // Ограничение длины строки в 15 символов
  const substring = `${string.substring(0, maxLength)}...`; // Выводиться первые 15 символов с (...) в конце
  const stringToRender = currentLength > maxLength ? substring : string; // Строка которая пойдёт на рендер

  return (
    <>
      <Tooltip title={string} placement={placement}>
        <SText ellipsis style={color ? { color } : ''}>
          {stringToRender}
        </SText>
      </Tooltip>
    </>
  );
};
