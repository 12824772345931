import { get, put, destroy, post } from 'redux-bees';

const commentsEndpoints = apiPath => ({
  getComments: { method: get, path: `${apiPath}/comments` },
  createComment: { method: post, path: `${apiPath}/comments` },
  deleteComment: { method: destroy, path: `${apiPath}/comments/:id` },
  updateComment: { method: put, path: `${apiPath}/comments/:id` },
  getReviewCommentsWithoutReplies: { method: get, path: `${apiPath}/review_comments` },
  createCommentReply: { method: post, path: `${apiPath}/comments/:id/replies` }
});

export default commentsEndpoints;
