import { isEqual, sortBy } from 'lodash';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import {
  exportWidgetData,
  getOrderedGroupQuestionsAndQuestionsAvito
} from 'pages/DashboardPage/utils';
import { prepareRows } from './prepareTableData';

export const TableReviewsWithScoresContext = React.createContext({
  loadingExport: false,
  startExport: () => {}
});

export const TableReviewsWithScoresProviderAvitoQg = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loadingExport, setLoadingExport] = useState(false);

  const usersByIds = useSelector(state => state.usersResource.byIds, isEqual);
  const unitsByIds = useSelector(state => state.unitsResource.byIds, shallowEqual);
  const questionGroupSubgroupsResource = Object.values(
    useSelector(state => state.checklistQuestionGroupSubgroupsResource.byIds)
  );
  const questionGroupResource = Object.values(
    useSelector(state => state.questionGroupsResource.byIds)
  );
  const questionsResource = useSelector(state => state.questionsResource.byIds, isEqual);

  const startExport = async ({
    id,
    type,
    filters,
    widgetMeta,
    initialState,
    currentChecklist,
    checklistItems
  }) => {
    const res = await dispatch(
      updateDashboardWidget({
        id,
        type,
        filters,
        page: {
          number: 1,
          size: widgetMeta.total_count
        }
      })
    );

    const getOrder = checklistItems => {
      const targetItems = [];
      const targetItemsIds = [];
      const groups = [];
      const groupsIds = [];
      const isHasGroup = checklistItems.some(obj => obj.type === 'checklist-question-groups');
      const isHasSubgroup = checklistItems.some(
        obj => obj.type === 'checklist-question-group-subgroups'
      );

      if (!isHasGroup && !isHasSubgroup) {
        const questions = checklistItems.filter(item => item.type === 'checklist-questions');
        if (questions) {
          questions.forEach(questions => {
            const { questionGroup } = questions.binding;
            if (questionGroup) {
              if (!groupsIds.includes(questionGroup.id)) {
                groups.push(questionGroup);
                groupsIds.push(questionGroup.id);
              }
            }
          });
          const sortedGroups = sortBy(groups, 'position');
          if (sortedGroups) {
            sortedGroups.forEach(sortedGroup => {
              const { questionsIds } = sortedGroup;
              if (questionsIds) {
                const questions = checklistItems.filter(item => questionsIds.includes(item.id));
                const sortedQuestions = sortBy(questions, 'binding.position');
                sortedQuestions.forEach(sortedQuestion => {
                  if (!targetItemsIds.includes(sortedQuestion.id)) {
                    targetItems.push(sortedQuestion);
                    targetItemsIds.push(sortedQuestion.id);
                  }
                });
              }
            });
          }
        }
        return targetItems;
      }
      return checklistItems;
    };

    const sortedItems = getOrder(checklistItems);
    const { viewMode } = filters;
    const targetItems = getOrderedGroupQuestionsAndQuestionsAvito({
      checklistItems,
      viewMode,
      type
    });

    const uniqueTargetItems = targetItems.reduce((acc, current) => {
      const found = acc.find(item => item.id === current.id);
      if (!found) {
        acc.push(current);
      }
      return acc;
    }, []);

    const columns = [
      {
        title: t('general.clientInteractionType'),
        dataIndex: 'reviewId',
        key: 'reviewId'
      },
      {
        title: t('constants.columnTypes.communicationLink.title'),
        dataIndex: 'communicationLink',
        key: 'communicationLink'
      },
      {
        title: t('general.operator'),
        dataIndex: 'operatorId',
        key: 'operatorId'
      },
      {
        title: t('general.unit'),
        dataIndex: 'unitName',
        key: 'unitName'
      },
      {
        title: t('dashboardPage.tableReviewsWithScores.table.columns.communicationKey'),
        dataIndex: 'communicationKey',
        key: 'communicationKey'
      },
      {
        title: t('general.clientInteractionDate'),
        dataIndex: 'clientInteractionStartedAt',
        key: 'clientInteractionStartedAt'
      },
      {
        title: t('general.reviewDate'),
        dataIndex: 'createdAt',
        key: 'createdAt'
      },
      ...uniqueTargetItems.map(item => {
        return {
          title: item?.name,
          dataIndex: item?.id,
          key: item?.id
        };
      }),
      {
        title: t('general.result'),
        dataIndex: 'checklistScore',
        key: 'checklistScore'
      },
      {
        title: 'Базовая оценка',
        dataIndex: 'basicAssessment',
        key: 'basicAssessment'
      }
    ];

    const body = {
      widgetType: type,
      widgetData: {
        columns,
        rows: prepareRows({
          filters,
          currentChecklist,
          widgetValue: res?.updatedAnalytics,
          checklistItems: sortedItems,
          isExport: true,
          usersByIds,
          unitsByIds,
          questionGroupSubgroupsResource,
          questionGroupResource
        })
      }
    };

    const { rows } = body.widgetData;
    let sum;
    const maximumPossibleScore = [];
    rows.forEach(() => {
      sum = 0;
      if (viewMode === 'questions') {
        uniqueTargetItems.forEach(item => {
          sum += item.max;
        });
      } else if (viewMode === 'subgroups') {
        uniqueTargetItems.forEach(item => {
          const { questionsIds } = item;
          questionsIds.forEach(question => {
            sum += questionsResource[question].max;
          });
        });
      } else if (viewMode === 'groups') {
        uniqueTargetItems.forEach(group => {
          if (group.questionGroupSubgroupsIds.length === 0) {
            const { questionsIds } = group;
            questionsIds.forEach(question => {
              sum += questionsResource[question].max;
            });
          }
          const subgroupQuestionsObject = questionGroupSubgroupsResource.filter(
            obj =>
              obj.checklistDefinitionId === currentChecklist?.id &&
              obj.questionGroupId &&
              obj.questionGroupId.includes(group?.id)
          );
          if (subgroupQuestionsObject) {
            subgroupQuestionsObject.forEach(subgroup => {
              const { questionsIds } = subgroup;
              questionsIds.forEach(question => {
                sum += questionsResource[question].max;
              });
            });
          }
        });
      }
      maximumPossibleScore.push(sum);
    });

    maximumPossibleScore.forEach((value, index) => {
      body.widgetData.rows[index].max = {
        value,
        color: 'var(--green_4)'
      };
    });

    body.widgetData.columns.push({
      title: t('general.maxRate'),
      dataIndex: 'max',
      key: 'max'
    });

    await exportWidgetData({ body, setLoadingExport });

    await dispatch(
      updateDashboardWidget({
        id,
        type,
        filters,
        page: initialState
      })
    );
  };

  return (
    <TableReviewsWithScoresContext.Provider value={{ loadingExport, startExport }}>
      {children}
    </TableReviewsWithScoresContext.Provider>
  );
};

export const useTableReviewsWithScoresExport = () => {
  const { loadingExport, startExport } = useContext(TableReviewsWithScoresContext);
  return { loadingExport, startExport };
};
