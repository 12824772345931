export default [
  'communicationsTypes',
  'direction',
  'npsFrom',
  'npsTo',
  'durationFrom',
  'durationTo',
  'clientInteractionSymbolicTimeRange',
  'clientInteractionTimeFrom',
  'clientInteractionTimeTo',
  'unitsIds',
  'operatorsIds',
  'userLevelsIds',
  'statusesIds',
  'isReviewed',
  'clientInteractionsIds',
  'clientPhoneNumber',
  'reviewsCountFrom',
  'reviewsCountTo',
  'checklistDefinitionsIds',
  'checklistScoreFrom',
  'checklistScoreTo',
  'communicationPartsCountFrom',
  'communicationPartsCountTo',
  'reviewersIds',
  'reviewTimeFrom',
  'reviewTimeTo',
  'reviewSymbolicTimeRange',
  'reviewsIds',
  'commentsRatingFlags',
  'taskDefinitionsIds',
  'hasTasks',
  'taskPriorities',
  'taskStatuses',
  'taskAssigneesIds',
  'pipelineStatusesIds',
  'pipelinesIds'
];

export const reviewFilters = [
  'reviewsCountFrom',
  'reviewsCountTo',
  'checklistDefinitionsIds',
  'checklistScoreFrom',
  'checklistScoreTo',
  'communicationPartsCountFrom',
  'communicationPartsCountTo',
  'reviewersIds',
  'reviewTimeFrom',
  'reviewTimeTo',
  'reviewSymbolicTimeRange'
];

export const tasksFilters = [
  'commentsRatingFlags',
  'taskDefinitionsIds',
  'hasTasks',
  'taskPriorities',
  'taskStatuses',
  'taskAssigneesIds'
];
