import { apiUrl } from 'core/config';
import { handleError } from 'core/services/errors';
import { buildApi, invalidateRequests } from 'redux-bees';
import amoEndpoints from './endpoints/amocrm';
import analyticsEndpoints from './endpoints/analytics';
import assignmentsEndpoints from './endpoints/assignments';
import authPasswordEndpoints from './endpoints/authPassword';
import backgroundJobsEndpoints from './endpoints/backgroundJobs';
import bitrixEndpoints from './endpoints/bitrix';
import calibrationSessionsEndpoints from './endpoints/calibrationSessions';
import phoneCallsEndpoints from './endpoints/calls';
import communicationChainsEndpoints from './endpoints/communicationChains';
import checklistDefinitionsEndpoints from './endpoints/checklistDefinitions';
import checklistsEndpoints from './endpoints/checklists';
import clientInteractionsEndpoints from './endpoints/clientInteractions';
import clientsEndpoints from './endpoints/clients';
import commentsEndpoints from './endpoints/comments';
import communicationTablesEndpoints from './endpoints/communicationTables';
import customCommunicationsEndpoints from './endpoints/customCommunication';
import customFieldsEndpoints from './endpoints/customFields';
import dashboardAnalyticsEndpointsV3 from './endpoints/dashboardAnalyticsV3';
import dashboardRowsEndpoints from './endpoints/dashboardRows';
import dashboardEndpoints from './endpoints/dashboards';
import dashboardWidgetsEndpoints from './endpoints/dashboardWidgets';
import exportsEndpoints from './endpoints/exports';
import feedbackEndpoints from './endpoints/feedback';
import foldersEndpoints from './endpoints/folders';
import integrationsEndpoints from './endpoints/integrations';
import folderItemsEndpoints from './endpoints/items';
import levelsEndpoints from './endpoints/levels';
import notificationSettingsEndpoints from './endpoints/notificationSettings';
import organizationEndpoints from './endpoints/organization';
import paymentEndpoints from './endpoints/payment';
import phoneCallChainsEndpoints from './endpoints/phoneCallChains';
import questionGroupsEndpoints from './endpoints/questionGroups';
import questionsEndpoints from './endpoints/questions';
import questionToGroupBindingsEndpoints from './endpoints/questionToGroupBindings';
import reviewLibrariesEndpoints from './endpoints/reviewLibraries';
import reviewLibraryItemsEndpoints from './endpoints/reviewLibraryItems';
import reviewsEndpoints from './endpoints/reviews';
import rolesEndpoints from './endpoints/roles';
import salePipelinesEndpoints from './endpoints/salePipelines';
import searchEndpoints from './endpoints/search';
import sipuniEndpoints from './endpoints/sipuni';
import statusesEndpoints from './endpoints/statuses';
import taskDefinitionsEndpoints from './endpoints/taskDefinitions';
import taskPriorityEndpoints from './endpoints/taskPriority';
import tasksEndpoints from './endpoints/tasks';
import textCommunicationsEndpoints from './endpoints/textCommunications';
import unitsEndpoints from './endpoints/units';
import uploadedFilesEndpoints from './endpoints/uploadedFiles';
import userAccountBindingsEndpoints from './endpoints/userAccountBindings';
import usersEndpoints from './endpoints/users';
import workPlanTaskConfigurationsEndpoints from './endpoints/workPlanTaskConfigurations';
import workPlanTaskAssignmentsEndpoints from './endpoints/workPlanTaskAssignments';
import commentTemplatesEndpoints from './endpoints/commentTemplates';
import appellationsEndpoints from './endpoints/appellations';
import appellationsSettingsEndpoints from './endpoints/appellationsSettings';
import { resourceApiRequestsBuilder } from './resourceApiRequestsBuilder';
import appellationCommentsEndpoints from './endpoints/appellationComments';
import usedeskEndpoints from './endpoints/usedesk';
import binotelEndpoints from './endpoints/binotel';
import zendeskEndpoints from './endpoints/zendesk';
import helpDeskEddyEndpoints from './endpoints/helpDeskEddy';
import infinityEndpoints from './endpoints/infinity';
import brightPatternEndpoints from './endpoints/brightPattern';
import appellationDashboardPageEndpoints from './endpoints/dashboardPages/appellationDashboardPage';
import autofailDashboardPageEndpoints from './endpoints/dashboardPages/autofailDashboardPage';
import unitDashboardPageEndpoints from './endpoints/dashboardPages/unitDashboardPage';
import unitByTypeDashboardPageEndpoints from './endpoints/dashboardPages/unitsByTypeDashboardPage';
import entitiesEndpoints from './endpoints/entities';
import nestedReviewEndpoints from './endpoints/nestedReview';
import checklistFormulasEndpoints from './endpoints/checklistFormulas';
import checklistQuestionGroupSubgroupsEndpoints from './endpoints/checklistQuestionGroupSubgroupsEndpoints';
import questionToGroupSubgroupBindingsEndpoints from './endpoints/questionToGroupSubgroupBindingsEndpoints';
import dashboardAnalyticsWidgetEndpoints from './endpoints/dashboardAnalyticsWidgetEndpoints';
import dashboardAnalyticsEndpoints from './endpoints/dashboardAnalytics';
import exportsEndpointsV3 from './endpoints/exportV3';
import communicationChainsV3Endpoints from './endpoints/communicationChainsEndpoint';

export const API_V1_PATH = '/api/v1';
export const API_V2_PATH = '/api/v2';
export const API_V3_PATH = '/api/v3';

const apiEndpoints = {
  ...commentsEndpoints(API_V1_PATH),
  ...unitsEndpoints(API_V1_PATH),
  ...reviewsEndpoints(API_V1_PATH),
  ...organizationEndpoints(API_V1_PATH),
  ...checklistsEndpoints(API_V1_PATH),
  ...checklistDefinitionsEndpoints(API_V1_PATH),
  ...phoneCallsEndpoints(API_V1_PATH),
  ...communicationChainsEndpoints(API_V1_PATH),
  ...assignmentsEndpoints(API_V1_PATH),
  ...usersEndpoints(API_V1_PATH),
  ...analyticsEndpoints(API_V1_PATH),
  ...integrationsEndpoints(API_V1_PATH),
  ...tasksEndpoints(API_V1_PATH),
  ...taskDefinitionsEndpoints(API_V1_PATH),
  ...taskPriorityEndpoints(API_V1_PATH),
  ...dashboardEndpoints(API_V1_PATH),
  ...dashboardRowsEndpoints(API_V1_PATH),
  ...dashboardWidgetsEndpoints(API_V1_PATH),
  ...dashboardAnalyticsEndpoints(API_V1_PATH),
  ...dashboardAnalyticsEndpointsV3(API_V3_PATH),
  ...clientsEndpoints(API_V1_PATH),
  ...clientInteractionsEndpoints(API_V1_PATH),
  ...bitrixEndpoints(API_V1_PATH),
  ...backgroundJobsEndpoints(API_V1_PATH),
  ...textCommunicationsEndpoints(API_V1_PATH),
  ...paymentEndpoints(API_V1_PATH),
  ...communicationTablesEndpoints(API_V1_PATH),
  ...paymentEndpoints(API_V1_PATH),
  ...feedbackEndpoints(API_V1_PATH),
  ...searchEndpoints(API_V1_PATH),
  ...customCommunicationsEndpoints(API_V1_PATH),
  ...rolesEndpoints(API_V1_PATH),
  ...notificationSettingsEndpoints(API_V1_PATH),
  ...amoEndpoints(API_V1_PATH),
  ...reviewLibrariesEndpoints(API_V1_PATH),
  ...reviewLibraryItemsEndpoints(API_V1_PATH),
  ...reviewLibraryItemsEndpoints(API_V1_PATH),
  ...questionToGroupBindingsEndpoints(API_V1_PATH),
  ...questionsEndpoints(API_V1_PATH),
  ...questionGroupsEndpoints(API_V1_PATH),
  ...exportsEndpoints(API_V1_PATH),
  ...levelsEndpoints(API_V1_PATH),
  ...authPasswordEndpoints(API_V1_PATH),
  ...foldersEndpoints(API_V1_PATH),
  ...folderItemsEndpoints(API_V1_PATH),
  ...calibrationSessionsEndpoints(API_V1_PATH),
  ...statusesEndpoints(API_V1_PATH),
  ...customFieldsEndpoints(API_V1_PATH),
  ...salePipelinesEndpoints(API_V1_PATH),
  ...phoneCallChainsEndpoints(API_V1_PATH),
  ...userAccountBindingsEndpoints(API_V1_PATH),
  ...workPlanTaskConfigurationsEndpoints(API_V1_PATH),
  ...workPlanTaskAssignmentsEndpoints(API_V1_PATH),
  ...sipuniEndpoints(API_V1_PATH),
  ...uploadedFilesEndpoints(API_V1_PATH),
  ...commentTemplatesEndpoints(API_V1_PATH),
  ...appellationsEndpoints(API_V1_PATH),
  ...appellationsSettingsEndpoints(API_V1_PATH),
  ...appellationCommentsEndpoints(API_V1_PATH),
  ...usedeskEndpoints(API_V1_PATH),
  ...binotelEndpoints(API_V1_PATH),
  ...zendeskEndpoints(API_V1_PATH),
  ...helpDeskEddyEndpoints(API_V1_PATH),
  ...infinityEndpoints(API_V1_PATH),
  ...brightPatternEndpoints(API_V1_PATH),
  ...appellationDashboardPageEndpoints(API_V1_PATH),
  ...autofailDashboardPageEndpoints(API_V1_PATH),
  ...unitDashboardPageEndpoints(API_V1_PATH),
  ...unitByTypeDashboardPageEndpoints(API_V1_PATH),
  ...entitiesEndpoints(API_V1_PATH),
  ...nestedReviewEndpoints(API_V2_PATH),
  ...checklistFormulasEndpoints(API_V1_PATH),
  ...checklistQuestionGroupSubgroupsEndpoints(API_V1_PATH),
  ...questionToGroupSubgroupBindingsEndpoints(API_V1_PATH),
  ...dashboardAnalyticsWidgetEndpoints(API_V3_PATH),
  ...exportsEndpointsV3(API_V3_PATH),
  ...communicationChainsV3Endpoints(API_V3_PATH)
};

export const clearEndpointCache = endpointName => invalidateRequests(apiEndpoints[endpointName]);

export const callUploadEndpoint = `${apiUrl}${API_V1_PATH}/calls/upload`;
export const exportToExcelEndpoint = `https://excel-exporter.qolio.ru`;
export const exportToExcelTableCommunicationEndpoint = `${apiUrl}${API_V3_PATH}/export`;
export const calculateFormulas = `${apiUrl}${API_V3_PATH}/formulas`;
export const deleteEmployeeRequest = `${apiUrl}${API_V3_PATH}/users`;
export const downloadCallFromMediaUrl = `${apiUrl}${API_V3_PATH}/get_media`;
export const addCommunicationChainsEndpoint = `${apiUrl}${API_V1_PATH}/communication_chains`;
export const updateCommunicationChainsEndpoint = `${apiUrl}${API_V1_PATH}/communication_chains/add_communication`;
export const deleteCommunicationFromChainEndpoint = `${apiUrl}${API_V1_PATH}/communication_chains/delete_communication`;
export const getCommunicationChains = `${apiUrl}${API_V2_PATH}/communications_tables/`;
export const createMassCommunicationChainsEndpoint = `${apiUrl}${API_V3_PATH}/bulk_action`;
export const checkMassCommunicationChainsEndpoint = `${apiUrl}${API_V3_PATH}/bulk_action/check`;
// export const exportToExcelEndpoint = `http://localhost:3000/`;

// TODO: replace headers object to authHeaders util
const config = {
  baseUrl: `${apiUrl}`,
  configureHeaders(headers) {
    return {
      ...headers,
      'Content-Type': 'application/json',
      'access-token': localStorage.getItem('access-token'),
      client: localStorage.getItem('client'),
      uid: localStorage.getItem('uid')
    };
  },
  afterReject({ status, headers, body, ...props }) {
    console.log({ status, headers, body, ...props });

    handleError(status, body);
    return Promise.reject({ status, headers, body });
  }
};

const api = buildApi(apiEndpoints, config);

export default api;

export const buildResourceApiRequest = resourceApiRequestsBuilder({ api });
