import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { get } from 'lodash';
import { Col, Spin, Row } from 'antd';
import SMLWidgetHeader from 'pages/DashboardPage/Components/SMLWidgetHeader';
import { widgetValueTypeSetter } from '../utils';
import { SmallWidgetCard, Value } from '../styled';
import EmptyWidget from '../Components/EmptyWidget';

const ScoreWidget = ({ widgetData, loadingWidgetId, isWarningWidget }) => {
  const checklistDefinitionsByIds = useSelector(
    state => state.checklistDefinitionsResource.byIds,
    shallowEqual
  );
  const { id, type, filters, name } = widgetData;

  const widgetValue = useSelector(state => state.dashboardAnalytics.byIds[id]);

  const checklistDefinitionRatingMode = get(
    checklistDefinitionsByIds,
    filters.checklistDefinitionsIds,
    {}
  )?.ratingMode;

  return (
    <>
      {isWarningWidget ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ) : (
        <Col span={4} key={id}>
          <SmallWidgetCard>
            <SMLWidgetHeader name={name} type={type} widgetData={widgetData} filters={filters} />
            <Spin spinning={loadingWidgetId === id}>
              <Row>
                <Value>
                  {widgetValue
                    ? `${widgetValueTypeSetter[type](widgetValue, checklistDefinitionRatingMode)}`
                    : 0}
                </Value>
              </Row>
            </Spin>
          </SmallWidgetCard>
        </Col>
      )}
    </>
  );
};

export default ScoreWidget;
