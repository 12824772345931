import { createReduxResource } from 'core/redux/resources';
import api from 'core/api';

export const communicationChainsV1Resource = createReduxResource({
  name: 'communicationChains',
  customOperations: {
    loadCommunicationChainIdsByIds: {
      callEndpoint: requestOptions => {
        const { ids: communicationChainsIds } = requestOptions;
        const ids = communicationChainsIds.split(',');
        return api.getCommunicationChains({ ids });
      },
      onSuccess: ({ result, dispatch }) => {
        dispatch({ type: 'communicationChains/loadSucceed', payload: result });
        return result;
      }
    }
  }
});
