export default {
  components: {
    colorPicker: {
      name: 'Цвет',
      namePlaceholder: 'Выберите цвет'
    },

    rateValues: {
      text: {
        placeholder: 'Текстовое значение'
      }
    },

    checklistManager: {
      autoFailModal: {
        title: 'Желаете завершить проверку?',
        description:
          'Активирован автофейл. Вы можете продолжить проверку, или завершить с сохранением текущих результатов.',
        ok: 'Завершить',
        cancel: 'Продолжить проверку'
      },
      generalCommentModal: {
        title: 'Общий комментарий',
        ok: 'Продолжить',
        cancel: 'Отмена'
      },
      textAreaPlaceholder: 'Общий комментарий',
      totalScore: 'Итоговый балл:',
      question: {
        autoFailTooltip: {
          title: 'Произошёл автофейл'
        },
        showStandardCommentSelector: {
          placeholder: 'Причина оценки'
        }
      },

      questionDescriptionModal: {
        modal: {
          ok: 'Продолжить',
          cancel: 'Отмена',
          alert: {
            title: 'Описание'
          }
        }
      },

      checklistManagerHead: {
        emptyEditingChecklistDefinition: {
          title: 'Форма оценки не установлена'
        },
        emptyChecklistModal: {
          title: 'Вы не выбрали форму оценки для данной проверки',
          description: 'Продолжить?',
          ok: 'Да',
          cancel: 'Нет'
        },
        allQuestionsAnswered: {
          title: 'Вы не оценили один или несколько критериев',
          description: 'Завершить проверку?',
          ok: 'Да',
          cancel: 'Нет'
        },
        confirmChangeChecklist: {
          title: 'Поменять форму оценки?',
          description: 'Это сбросит текущую проверку',
          ok: 'Да',
          cancel: 'Нет'
        },
        confirmDeleteEmployee: {
          title: 'Удалить пользователя?',
          description:
            'Уверены, что хотите удалить пользователя? При этом будут удалены все данные, связанные с этим пользователем (коммуникации, проверки, дашборды).',
          ok: 'Да',
          cancel: 'Нет',
          errors: {
            errorTimeoutTitle: 'Удаление пользователя',
            errorTimeout:
              'Похоже, что это займет немного больше времени. Можете продолжать работу в системе, пользователь вскоре будет удален.'
          }
        },
        confirmDeleteChecklist: {
          title: 'Удалить проверку?',
          description: 'Удаленная проверка не подлежит восстановлению',
          ok: 'Да',
          cancel: 'Нет',
          messages: {
            reviewSuccessfullyDeleted: 'Проверка удалена',
            deleteReviewFailed: 'Не удалось удалить проверку'
          }
        },
        menu: {
          changeCurrentChecklist: 'Поменять форму оценки',
          navigateToClientInteraction: 'Новая проверка',
          addToCalibration: 'Добавить к калибровочной сессии',
          deleteReview: 'Удалить проверку'
        },
        buttons: {
          emptyReview: 'Нет оценки',
          submitReview: 'Завершить проверку',
          editReview: 'Редактировать оценку',
          saveReview: 'Сохранить оценку',
          updateReview: 'Обновить оценку',
          inProcessReview: 'Идёт расчёт'
        },
        messages: {
          unsavedScore: 'При переходе на другую страницу результаты проверки не будут сохранены.'
        }
      },
      selectChecklist: {
        emptyAdminMessage: {
          pt1: 'Форма оценки для данной коммуникации или отдела не настроена. Перейдите к',
          pt2: ' списку форм оценок',
          pt3: ' для установки корректных настроек.'
        },
        emptyUserMessage:
          'Форма оценки для данной коммуникации или отдела не настроена. Обратитесь к администратору портала для корректной установки настроек.'
      }
    },

    customRatingValuesEditor: {
      answer: { placeholder: 'Введите текст' }
    },

    commentList: {
      comment: {
        commentEditButton: {
          buttons: {
            edit: 'Редактировать',
            answer: 'Ответить',
            delete: 'Удалить',
            hidden: 'Скрытый',
            cancel: 'Отменить',
            save: 'Сохранить'
          },
          confirmDelete: {
            title: 'Удалить комментарий?',
            ok: 'Да',
            cancel: 'Нет'
          }
        },
        commentInfo: {
          hiddenCommentTooltip: 'Скрытый комментарий',
          answersCount: 'Ответов:'
        },
        createReplyForm: {
          buttons: {
            hidden: 'Скрытый',
            cancel: 'Отменить',
            send: 'Отправить'
          }
        }
      }
    },

    commentsPanel: {
      buttons: {
        addComment: 'Добавить комментарий',
        hidden: 'Скрытый',
        cancel: 'Отмена',
        send: 'Отправить'
      },
      commentPlaceholder: 'Комментарий...'
    },

    commentsPreview: {
      leftACommentStr: 'оставил комментарий в:',
      commentPopoverTitle: 'Комментарии',
      previewIcons: {
        title: 'Комментарии',
        generalComment: 'Итоговый комментарий'
      }
    },

    timeSpecificSelector: {
      placeholder: 'Выберите период',
      otherPeriod: 'За период',
      selectButton: 'Выбрать'
    },

    notifications: {
      logged: 'Вы вошли!'
    },

    recordPlayer: {
      loading: 'Загружаем звонок...',
      addToLibrary: {
        messages: {
          recordStr: 'Запись',
          addedStr: 'успешно добавлена в',
          addRecordToLibraryFailed: 'Не удалось добавить запись'
        },
        tooltip: 'Добавить в библиотеку',
        modal: {
          title: 'Добавить в библиотеку',
          ok: 'Сохранить',
          cancel: 'Отмена',
          enterFolderName: 'Выберите название папки:',
          folderNamePlaceholder: 'Название папки:',
          enterRecordName: 'Введите название звонка:',
          recordNamePlaceholder: 'Название звонка'
        }
      },
      viewCustomFields: {
        tooltip: 'Дополнительная информация'
      },
      attachTag: {
        title: 'Добавить тег'
      },

      download: 'Скачать',
      copyLink: 'Копировать ссылку',
      copyReview: 'Копировать ссылку на оценку',
      copyCommunication: 'Копировать ссылку на коммуникацию',
      copyLinkSuccess: 'Ссылка скопирована',
      externalLink: 'Перейти во внешнюю систему',
      recordControls: {
        play: 'Воспроизвести',
        backward: '-15 сек.',
        forward: '+15 сек.'
      },
      phoneCallInformation: {
        callDate: 'Дата звонка: ',
        callDateChain: 'Дата цепочки: ',
        callType: 'Тип: ',
        callNumber: 'Номер: ',
        callNumberChain: 'Номер цепочки: ',
        tooltipButtonInfo:
          'Оператор, на которого записываются звонок и проверки звонка. Если по звонку уже совершены проверки, то при смене оператора они будут перезаписаны на нового оператора.',
        searchPlaceholder: 'Поиск по операторам',
        generalDuration: 'Длительность: ',
        generalDurationTooltip: 'Общая продолжительность цепочки коммуникаций'
      }
    },

    questionModal: {
      title: 'Настройки критерия',
      buttons: {
        addComment: 'Добавить комментарий',
        save: 'Сохранить',
        update: 'Обновить',
        cancel: 'Отменить'
      },
      form: {
        fields: {
          namePlaceholder: 'Название критерия',
          nameInfo: 'Название критерия будет отображаться в форме оценки.',
          name: 'Название',

          description: 'Описание',
          descriptionPlaceholder: 'Описание критерия',
          descriptionInfo:
            'Опишите, какие именно компетенции, знания и навыки вы хотите проверить с помощью этого критерия. Это описание поможет вам при выборе критериев из Списка критериев для формирования полноценной формы оценки.',

          scaleType: 'Шкала оценки',
          scaleTypeInfo:
            'Выберите готовую шкалу для оценки данного критерия либо в “Произвольной шкале” укажите те баллы, которыми хотите оценивать сотрудников по этому критерию.',

          questionType: 'Тип критерия',
          questionTypeInfo: 'Выберите тип критерия',

          valuesDisplayType: 'Отображение в форме оценки',
          valuesDisplayTypeInfo:
            'То в каком виде шкала будет отображаться в форме оценки во время проверки.',

          defaultRatingValueEnabled: 'Значение в форме оценки по умолчанию',
          defaultRatingValueEnabledInfo:
            'Вы можете указать, какой балл будет автоматически выставлен по данному критерию.',

          autofailEnabled: 'Автофейл формы оценки',
          autofailEnabledInfo:
            'При определенной оценке по данному критерию итоговый балл будет минимальным из возможных.',
          autofailCriteriesAlert: 'Настройте критерии',
          autofailTotalScore: 'Итог формы оценки:',
          questionGroupAutofail: 'Автофейл группы',
          questionGroupAutofailCriteriesAlert: 'Настройте критерии',
          questionGroupAutofailTotalScore: 'Итог группы:',
          autofailEnabledAlert: {
            title: 'Автофейл',
            description:
              'Укажите балл или несколько баллов по данному критерию, при появлении которых итоговая оценка по всеей форме будет равна минимально возможному значению.'
          },

          standardCommentsEnabled: 'Причина оценки',
          standardCommentsEnabledInfo:
            'Напишите комментарии, которые вы чаще всего добавляете при выставлении конкретного балла в этом критерии.'
        },
        messages: {
          enterName: 'Пожалуйста введите название'
        },
        types: {
          base: 'Базовый',
          advanced: 'Продвинутый'
        },
        questionAnswers: 'Критерии:'
      }
    },
    registerFromIntegration: {},
    textCommunicationContent: {
      conversationInfo: {
        date: 'Дата: ',
        communicationId: 'Чат: ',
        title: 'Тема: '
      },
      conversationPart: {
        client: 'Клиент',
        operator: 'Оператор',
        buttons: {
          comment: 'Комментировать',
          viewComments: 'Посмотреть комментарии',
          addComment: 'Добавить комментарий'
        },
        emptyComments: 'Нет комментариев'
      }
    },
    userPreview: {
      userNotFound: 'Пользователь не найден',
      userNotInstalled: 'Пользователь не установлен'
    },
    addToCalibrationModal: {
      messages: {
        fileSuccessfullyAddedToGroup: 'Файл добавлен в группу',
        addFileToGroupFailed: 'Не удалось добавить файл в группу',
        communicationGroupSuccessfullyCreated: 'Группа коммуникаций создана',
        communicationGroupCreateFailed: 'Не удалось создать группу'
      },
      title: 'Сохранить оценку для калибровки',
      ok: 'Продолжить',
      cancel: 'Отмена',
      form: {
        fileName: 'Название файла:',
        callName: 'Название звонка',
        folderName: 'Выберите название папки:',
        folderNamePlaceholder: 'Название группы коммуникаций',
        buttons: {
          addGroup: 'Добавить группу'
        }
      }
    },
    calibrationSessionStatusTag: {
      unistalledTag: 'Не установлен'
    },
    checklistDefinitionStatusTag: {
      unistalledTag: 'Не установлен'
    },
    checklistHistoryModal: {
      loading: 'Вспоминаем динамику...🙄',
      empty: 'Нет данных за выбранный период 😕'
    },
    checklistsDefinitionsSelect: {
      allChecklists: 'Все формы оценки'
    },
    checklistsList: {
      messages: {
        copySuccessfullyCreated: 'Копия создана',
        createCopyFailed: 'Не удалось создать копию',
        sumPercentageLessThanHundren: 'Сумма весов критериев в форме оценки должна составить 100%',
        emptyQuestions: 'Для публикации в форму оценки должны быть добавлены критерии'
      },
      publishChecklistConfirm: {
        title: 'Опубликовать форму оценки?',
        description:
          'После публикации формы оценки вы не сможете редактировать следующие параметры: метод и расчет оценки, шкалу оценки и веса критериев. \n Перед публикацией убедитесь что все настроено верно.',
        ok: 'Продолжить',
        cancel: 'Отмена'
      },
      confirmChecklistArchive: {
        title: 'Переместить форму оценки в архив?',
        description: 'Архивированные формы оценки недоступны для выбора во время проверки',
        ok: 'В архив',
        cancel: 'Отмена'
      },
      confirmChecklistDelete: {
        title: 'Вы уверены что хотите удалить форму оценки?',
        description: 'Это действие необратимо',
        ok: 'Удалить',
        cancel: 'Отмена'
      },
      checklistQuestionsStr: ' критериев',
      menu: {
        publish: 'Опубликовать',
        edit: 'Настройка',
        view: 'Просмотр',
        settings: 'Настройка формы оценки',
        copy: 'Создать копию',
        archive: 'Переместить в архив',
        delete: 'Удалить форму оценки'
      },
      listColumns: {
        name: 'Название',
        description: 'Описание',
        createdAt: 'Cоздан',
        author: 'Автор',
        status: 'Статус',
        actions: 'Действия'
      },
      loading: 'Загружаем формы оценки...😤',
      listLoaded: 'Это все! 😊',
      calculactorEditorPlaceholder: 'Формула расчета...'
    },
    colorZones: {
      list: {
        name: 'Название',
        color: 'Цвет',
        description: 'Описание',
        range: 'Диапазон оценок'
      },
      scores: {
        high: 'Отлично',
        medium: 'Нормально',
        low: 'Критично'
      }
    },
    colorZonesBeforePublishModal: {
      title: 'Настройка зон оценки',
      ok: 'Опубликовать',
      cancel: 'Отмена'
    },
    commentsModal: {
      title: 'Комментарии',
      empty: 'Нет комментариев'
    },
    durationPicker: {
      from: 'От',
      to: 'До'
    },
    questionDetailsModal: {
      ok: 'Продолжить',
      cancel: 'Отмена',
      loading: 'Вспоминаем динамику...🙄 ',
      empty: 'Нет данных за выбранный период 😕'
    },
    questionsList: {
      messages: {
        blockedDeleteQuestion:
          'Нельзя удалить критерий, добавленный к опубликованной или архивированной форме оценки'
      },
      confirmDelete: {
        title: 'Вы уверены что хотите удалить критерий?',
        description: 'Это действие необратимо',
        ok: 'Удалить',
        cancel: 'Отмена'
      },
      menu: {
        edit: 'Редактировать критерий',
        copy: 'Создать копию',
        delete: 'Удалить критерий'
      },
      isNotAddedToChecklist: 'Не добавлен к формам оценки',
      and: 'и еще',
      listColumns: {
        name: 'Название',
        author: 'Автор',
        createdAt: 'Cоздан',
        description: 'Описание',
        isAddedToChecklist: 'Формы оценки',
        actions: 'Действия'
      },
      loading: 'Загружаем критерии...😤',
      listLoaded: 'Это все! 😊'
    },
    rangeInputs: {
      from: 'От',
      to: 'До'
    },
    communicationInfoModal: {
      title: 'Дополнительная информация',
      chain: 'Цепочка звонков',
      ok: 'Продолжить',
      emptyFieldDescription:
        'Не удалось найти описание поля. Чтобы избежать ошибок, пожалуйста опишите настройки всех custom fields.'
    },
    statusSelector: {
      selectStatus: 'Статус контакта',
      newStatusNamePlaceholder: 'Новый статус',
      resetStatus: 'Сбросить статус',
      addStatus: 'Добавить',
      blankNameError: `Название статуса не может быть пустым`
    },
    timestamp: 'Время:',
    appealHead: {
      sendAppeal: 'Отправить апелляцию',
      cancelAppeal: 'Отменить апелляцию',
      closeAppeal: 'Завершить обработку',
      editAppeal: 'Редактировать обработку',
      createAppeal: 'Подать апелляцию'
    },
    createTask: 'Поставить задачу',
    addUsersModal: 'Сотрудники',
    selectUsers: {
      admin: 'Администратор',
      selectUsersTooltip1: 'Пользователь с таким email',
      selectUsersTooltip2:
        'уже зарегистрирован в системе. Вы можете поменять его email и сделать его активным в настройках "Команды"'
    },
    syncBackgroundJob: {
      waitText: 'Это может занять несколько минут.',
      waitTextDescription: 'Вы можете подождать или сразу начать пользоваться приложением'
    },
    syncData: {
      selectSyncPeriod: 'Выберите период синхронизации'
    }
  }
};
