export default {
  components: {
    colorPicker: {
      name: 'Colore',
      namePlaceholder: 'Scegli un colore'
    },
    rateValues: { text: { placeholder: 'Valore del testo' } },
    checklistManager: {
      autoFailModal: {
        title: 'Vuoi finire la valutazione?',
        description:
          'Errore automatico attivato. Puoi continuare a controllare oppure uscire e salvare i risultati correnti.',
        ok: 'Finire',
        cancel: 'Continuare la valutazione'
      },
      generalCommentModal: {
        title: 'Commento generale',
        ok: 'Continuare',
        cancel: 'Annulla'
      },
      textAreaPlaceholder: 'Commento generale',
      totalScore: 'Valutazione totale:',
      question: {
        autoFailTooltip: { title: "L'archiviazione automatica e' stata attivata" },
        showStandardCommentSelector: { placeholder: 'Motivo della valutazione' }
      },
      questionDescriptionModal: {
        modal: {
          ok: 'Continuare',
          cancel: 'Annula',
          alert: { title: 'Descrizione' }
        }
      },
      checklistManagerHead: {
        emptyEditingChecklistDefinition: {
          title: 'Modulo di valutazione non stabilito'
        },
        emptyChecklistModal: {
          title: 'Non hai scelto un modulo per controllare questa valutazione',
          description: 'Continuare?',
          ok: 'Si',
          cancel: 'No'
        },
        allQuestionsAnswered: {
          title: "Non hai valutato uno o piu' elementi nell'elenco di controllo",
          description: 'Completare la valutazione?',
          ok: 'Si',
          cancel: 'No'
        },
        confirmChangeChecklist: {
          title: 'Cambiare la lista di controllo?',
          description: "Questo cancellera' la valutazione in corso",
          ok: 'Si',
          cancel: 'No'
        },
        confirmDeleteEmployee: {
          title: "Eliminare l'utente?",
          description:
            "Sei sicuro/a di voler eliminare l'utente? Questa azione rimuoverà tutti i dati associati a questo utente (comunicazioni, valutazioni, cruscotti).",
          ok: 'Si',
          cancel: 'No',
          errors: {
            errorTimeoutTitle: 'Eliminazione di un utente',
            errorTimeout:
              "Sembra che ci vorrà un po ' più di tempo. Puoi continuare a lavorare nel sistema, l'utente verrà presto eliminato."
          }
        },
        confirmDeleteChecklist: {
          title: 'Eliminare la valutazione?',
          description: "La valutazione eliminata non puo' essere ripristinata",
          ok: 'Si',
          cancel: 'No',
          messages: {
            reviewSuccessfullyDeleted: 'Valutazione eliminata',
            deleteReviewFailed: "La valutazione non e' stata eliminata"
          }
        },
        menu: {
          changeCurrentChecklist: 'Cambiare il modulo di valutazione',
          navigateToClientInteraction: 'Nuova valutazione',
          addToCalibration: 'Aggiungere alla sessione di calibrazione',
          deleteReview: 'Eliminare la valutazione'
        },
        buttons: {
          emptyReview: 'Nessuna valutazione',
          submitReview: 'Terminare la valutazione',
          editReview: 'Modifica valutazione',
          saveReview: 'Salva valutazione',
          updateReview: 'Aggiorna la valutazione',
          inProcessReview: 'Calcolo in corso'
        },
        messages: {
          unsavedScore:
            "Se si va a un'altra paggina, i risultati di valutazione non verranno salvati."
        }
      },
      selectChecklist: {
        emptyAdminMessage: {
          pt1:
            "Il modulo di valutazione non è stato configurato per questa comunicazione o reparto. Vai all'elenco dei",
          pt2: ' moduli di valutazione',
          pt3: ' per impostare le impostazioni corrette.'
        },
        emptyUserMessage:
          'Форма оценки для данной коммуникации или отдела не настроена. Обратитесь к администратору портала для корректной установки настроек.'
      }
    },
    customRatingValuesEditor: { answer: { placeholder: 'Inserire il testo' } },
    commentList: {
      comment: {
        commentEditButton: {
          buttons: {
            edit: 'Modificare',
            answer: 'Rispondere',
            delete: 'Eliminare',
            hidden: 'Nascosto',
            cancel: 'Annulla',
            save: 'Salvare'
          },
          confirmDelete: {
            title: 'Eliminare il commento?',
            ok: 'Si',
            cancel: 'No'
          }
        },
        commentInfo: {
          hiddenCommentTooltip: 'Commento nascosto',
          answersCount: 'Risposte:'
        },
        createReplyForm: {
          buttons: {
            hidden: 'Nascosto',
            cancel: 'Annulla',
            send: 'Inviare'
          }
        }
      }
    },
    commentsPanel: {
      buttons: {
        addComment: 'Aggiungere un commento',
        hidden: 'Nascosto',
        cancel: 'Annulla',
        send: 'Inviare'
      },
      commentPlaceholder: 'Commento...'
    },
    commentsPreview: {
      leftACommentStr: 'lasciato un commento in:',
      commentPopoverTitle: 'Commenti',
      previewIcons: {
        title: 'Commenti',
        generalComment: 'Commento finale'
      }
    },
    timeSpecificSelector: {
      placeholder: 'Scegli un periodo',
      otherPeriod: 'Per il periodo',
      selectButton: 'Scegliere'
    },
    notifications: { logged: "Hai effettuato l'accesso!" },
    recordPlayer: {
      loading: 'Caricamento in corso...',
      addToLibrary: {
        messages: {
          recordStr: 'Registrazione',
          addedStr: 'aggiunto con successo a',
          addRecordToLibraryFailed: "La registrazione non e' stata aggiunta"
        },
        tooltip: 'Aggiungere alla libreria',
        modal: {
          title: 'Aggiungere alla libreria',
          ok: 'Salvare',
          cancel: 'Annulla',
          enterFolderName: 'Scegli il nome della cartella:',
          folderNamePlaceholder: 'Nome della cartella:',
          enterRecordName: 'Inserisci il nome della chiamata:',
          recordNamePlaceholder: 'Nome della chiamata'
        }
      },
      viewCustomFields: { tooltip: 'Informazioni aggiuntive' },
      attachTag: { title: 'Aggiungi tag' },
      download: 'Scarica',
      copyLink: 'Copia link',
      copyReview: 'Copia il link di valutazione',
      copyCommunication: 'Copia collegamento comunicazione',
      copyLinkSuccess: 'Link copiato',
      externalLink: 'Vai al sistema esterno',
      recordControls: {
        play: 'Ascoltare la registrazione',
        backward: '-15 sec.',
        forward: '+15 sec.'
      },
      phoneCallInformation: {
        callDate: 'Data di chiamata: ',
        callDateChain: 'Data di chiamata: ',
        callType: 'Tipo: ',
        callNumber: 'Numero: ',
        callNumberChain: 'Numero: ',
        tooltipButtonInfo:
          "L'operatore a cui vengono registrati i controlli di chiamata e chiamata. Se sono già stati effettuati controlli sulla chiamata, quando si cambia operatore verranno riscritti al nuovo operatore.",
        searchPlaceholder: 'Ricerca per operatori',
        generalDuration: 'Durata: ',
        generalDurationTooltip: 'Durata totale della catena di comunicazione'
      }
    },
    questionModal: {
      title: 'Impostazioni del criterio',
      buttons: {
        addComment: 'Aggiungi un commento',
        save: 'Salvare',
        update: 'Aggiornare',
        cancel: 'Annulla'
      },
      form: {
        fields: {
          namePlaceholder: 'Nome del criterio',
          nameInfo: "Il nome del criterio verra' visualizzato nel modulo di valutazione.",
          name: 'Nome',
          description: 'Descrizione',
          descriptionPlaceholder: 'Descrizione del criterio',
          descriptionInfo:
            "Descrivi, quali competenze, conoscenze e abilita' vuoi valutare con questo criterio. Questa descrizione ti aiutera' a scegliere i criteri dall'elenco dei criteri per formare un elenco completo di valutazione.",
          scaleType: 'Scala di valutazione',
          scaleTypeInfo:
            'Scegli una scala gia\' pronta per valutare questo criterio o nella "Scala arbitraria" indica i punti che vuoi utilizzare per valutare gli amministratori in questo criterio.',
          
          questionType: 'Tipo di criterio',
          questionTypeInfo: 'Scegli il tipo di criterio',
          
          valuesDisplayType: 'Visualizzazione nel modulo di valutazione',
          valuesDisplayTypeInfo:
            "Il modo in cui la scala verra' visualizzata nel modulo di valutazione durante il controllo.",
          defaultRatingValueEnabled: 'Valore predefinito nel modulo di valutazione',
          defaultRatingValueEnabledInfo:
            "E' possibile specificare quale punto verra' assegnato automaticamente a questo criterio.",
          autofailEnabled: 'Archiviazione automatica del modulo della valutazione',
          autofailEnabledInfo:
            "Nella valutazione secondo questo criterio, il punteggio finale sara' il piu' basso possibile.",
          autofailCriteriesAlert: 'Personalizza i tuoi criteri',
          autofailTotalScore: 'Riepilogo del modulo di valutazione:',
          questionGroupAutofail: 'Autofile di gruppo',
          questionGroupAutofailCriteriesAlert: 'Personalizza i tuoi criteri',
          questionGroupAutofailTotalScore: 'Riepilogo del gruppo:',
          autofailEnabledAlert: {
            title: 'Errore automatico',
            description:
              "Specifica uno o piu' punti per questo criterio, quando il voto finale per tutto il modulo sara' uguale al volore minimo possibile."
          },
          standardCommentsEnabled: 'Motivo della valutazione',
          standardCommentsEnabledInfo:
            "Scrivi i commenti che aggiungi piu' spesso quando assegni un punteggio specifico in questo criterio."
        },
        messages: { enterName: 'Inserisci il nome' },
        questionAnswers: 'Criteri:'
      }
    },
    registerFromIntegration: {},
    textCommunicationContent: {
      conversationInfo: {
        date: 'Data: ',
        communicationId: 'Chat: '
      },
      conversationPart: {
        client: 'Cliente',
        operator: 'Operatore',
        buttons: {
          comment: 'Commentare',
          viewComments: 'Visualizza commenti',
          addComment: 'Aggiungere un commento'
        },
        emptyComments: 'Ancora nessun commento'
      }
    },
    userPreview: {
      userNotFound: 'Utente non trovato',
      userNotInstalled: 'Utente non installato'
    },
    addToCalibrationModal: {
      messages: {
        fileSuccessfullyAddedToGroup: 'File aggiunto nel gruppo',
        addFileToGroupFailed: "File non e' stato aggiunto al gruppo",
        communicationGroupSuccessfullyCreated: 'Gruppo di comunicazione creato',
        communicationGroupCreateFailed: "Gruppo non e' stato creato"
      },
      title: 'Salvare il voto per la calibrazione',
      ok: 'Continuare',
      cancel: 'Annulla',
      form: {
        fileName: 'Nome del file:',
        callName: 'Nome della chiamata',
        folderName: 'Scegli il nome per la cartella:',
        folderNamePlaceholder: 'Nome del gruppo di comunicazione',
        buttons: { addGroup: 'Aggiungere gruppo' }
      }
    },
    calibrationSessionStatusTag: { unistalledTag: 'Non installato' },
    checklistDefinitionStatusTag: { unistalledTag: 'Non installato' },
    checklistHistoryModal: {
      loading: 'Ricordando la dinamica...🙄',
      empty: 'Nessun dato per il periodo selezionato 😕'
    },
    checklistsDefinitionsSelect: { allChecklists: 'Tutte le forme di valutazione' },
    checklistsList: {
      messages: {
        copySuccessfullyCreated: 'Copia creata',
        createCopyFailed: "Copia non e' stata creata",
        sumPercentageLessThanHundren:
          'La somma dei criteri nel modulo di valutazione deve essere 100%',
        emptyQuestions:
          'Per la pubblicazione, i criteri devono essere aggiunti nel modulo di valutazione'
      },
      publishChecklistConfirm: {
        title: 'Pubblicare la checklist?',
        description:
          "Dopo la pubblicazione del modulo, non sara' possibile modificare i seguenti parametri: il medoto e il calcolo della valutazione, la scala di valutazione e il valore dei criteri. Assicurati che tutto sia configurato correttamente prima della pubblicazione.",
        ok: 'Continuare',
        cancel: 'Annulla'
      },
      confirmChecklistArchive: {
        title:
          'I moduli dei vuoti archiviati non sono disponibili per la selezione durante il controllo?',
        description:
          'I moduli di valutazione archiviate non possono essere scelte durante il controllo',
        ok: "All'archivio",
        cancel: 'Annulla'
      },
      confirmChecklistDelete: {
        title: 'Sei sicuro di voler eliminare il modulo di valutazione?',
        description: "Questa azione e' irreversibile",
        ok: 'Elimina',
        cancel: 'Annulla'
      },
      checklistQuestionsStr: ' criteri',
      menu: {
        publish: 'Pubblicare',
        edit: 'Impostazioni',
        view: 'Visualizza',
        settings: 'Impostazione del modulo di valutazione',
        copy: 'Creare una copia',
        archive: 'Sposta in archivio',
        delete: 'Elimina il modulo di valutazione'
      },
      listColumns: {
        name: 'Nome',
        description: 'Descrizione',
        createdAt: 'Creato',
        author: 'Autore',
        status: 'Stato',
        actions: 'Azioni'
      },
      loading: 'Caricamento dei moduli di valutazione...😤',
      listLoaded: "E' tutto! 😊"
    },
    colorZones: {
      list: {
        name: 'Nome',
        color: 'Colore',
        description: 'Descrizione',
        range: 'Gamma di valutazioni'
      },
      scores: {
        high: 'Eccellente',
        medium: 'Normale',
        low: 'Critico'
      }
    },
    colorZonesBeforePublishModal: {
      title: 'Impostazione delle zone di valutazione',
      ok: 'Pubblicare',
      cancel: 'Annulla'
    },
    commentsModal: {
      title: 'Commenti',
      empty: 'Ancora nessun commento'
    },
    durationPicker: {
      from: 'Da',
      to: 'A'
    },
    questionDetailsModal: {
      ok: 'Continuare',
      cancel: 'Annulla',
      loading: 'Ricordando la dinamica...🙄 ',
      empty: 'Nessun dato per il periodo selezionato 😕'
    },
    questionsList: {
      messages: {
        blockedDeleteQuestion:
          "Non e' possibile eliminare un criterio aggiunto ad un modullo pubblicato o archiviato"
      },
      confirmDelete: {
        title: 'Sei sicuro di voler eliminare un criterio?',
        description: "Questa azione e' irreversibile",
        ok: 'Elimina',
        cancel: 'Annulla'
      },
      menu: {
        edit: 'Modifica criterio',
        copy: 'Creare una copia',
        delete: 'Elimina criterio'
      },
      isNotAddedToChecklist: 'Non aggiunto ai moduli di valutazione',
      and: 'e altro ancora',
      listColumns: {
        name: 'Nome',
        author: 'Autore',
        createdAt: 'Creato',
        description: 'Descrizione',
        isAddedToChecklist: 'I moduli di valutazione',
        actions: 'Azioni'
      },
      loading: 'Caricamento dei criteri...😤',
      listLoaded: "E' tutto! 😊"
    },
    rangeInputs: {
      from: 'Da',
      to: 'A'
    },
    communicationInfoModal: {
      title: 'Informazioni aggiuntive',
      chain: 'Catena di chiamate',
      ok: 'Continuare',
      emptyFieldDescription:
        "La descrizione del campo non e' stata trovata. Per evitare errori, descrivete le impostazioni per tutti i campi personalizzati."
    },
    statusSelector: {
      selectStatus: 'Stato del contatto',
      newStatusNamePlaceholder: 'Nuovo stato',
      resetStatus: 'Ripristina lo stato',
      addStatus: 'Aggiungere',
      blankNameError: `Il nome dello stato non puo' essere vuoto`
    },
    timestamp: 'Tempo:',
    appealHead: {
      sendAppeal: 'Invia un appello',
      cancelAppeal: 'Annulla il ricorso',
      closeAppeal: 'Finire la lavorazione',
      editAppeal: 'Modifica elaborazione',
      createAppeal: 'Presentare un ricorso'
    },
    createTask: 'Imposta un compito',
    addUsersModal: 'Dipendenti',
    selectUsers: {
      admin: 'Amministratore',
      selectUsersTooltip1: 'Utente con questa email',
      selectUsersTooltip2:
        'già registrato nel sistema. Puoi cambiare la sua email e renderla attiva nelle impostazioni "Team"'
    },
    syncBackgroundJob: {
      waitText: 'Potrebbero essere necessari alcuni minuti.',
      waitTextDescription: "Puoi aspettare o iniziare subito a utilizzare l'applicazione"
    },
    syncData: { selectSyncPeriod: 'Seleziona il periodo di sincronizzazione' }
  }
};
