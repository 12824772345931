import { createAction, createReducer } from '@reduxjs/toolkit';
import { getStandardCommentsKeyByRate } from 'core/utils/standardComments';
import { get } from 'lodash';
import uniqid from 'uniqid';

const defaultState = {
  question: {},
  questionToGroupBinding: {}
};

export const setEditingQuestion = createAction('uiQuestionModal/setQuestion');
export const setEditingQuestionWithBinding = createAction(
  'uiQuestionModal/setEditingQuestionWithBinding'
);
export const updateEditingQuestion = createAction('uiQuestionModal/updateQuestion');
export const updateEditingQuestionToGroupBinding = createAction(
  'uiQuestionModal/updateEditingQuestionToGroupBinding'
);
export const updateQuestionStandardComment = createAction(
  'uiQuestionModal/updateQuestionStandardComment'
);
export const createQuestionStandardComment = createAction(
  'uiQuestionModal/createQuestionStandardComment'
);
export const deleteQuestionStandardComment = createAction(
  'uiQuestionModal/deleteQuestionStandardComment'
);

export const uiQuestionModalReducer = createReducer(defaultState, {
  [setEditingQuestion]: (state, { payload }) => ({ ...state, question: payload }),
  [setEditingQuestionWithBinding]: (state, { payload }) => ({
    ...state,
    question: payload.question,
    questionToGroupBinding: payload.questionToGroupBinding
  }),
  [updateEditingQuestion]: (state, { payload }) => ({
    ...state,
    question: { ...state.question, ...payload }
  }),
  [updateEditingQuestionToGroupBinding]: (state, { payload }) => ({
    ...state,
    questionToGroupBinding: { ...state.questionToGroupBinding, ...payload }
  }),
  [createQuestionStandardComment]: (state, { payload }) => ({
    ...state,
    question: {
      ...state.question,
      standardComments: {
        ...state.question.standardComments,
        [getStandardCommentsKeyByRate(payload.rate)]: {
          ...get(state.question.standardComments, getStandardCommentsKeyByRate(payload.rate), {}),
          [uniqid()]: ''
        }
      }
    }
  }),
  [updateQuestionStandardComment]: (state, { payload }) => {
    return {
      ...state,
      question: {
        ...state.question,
        standardComments: {
          ...state.question.standardComments,
          [getStandardCommentsKeyByRate(payload.rate)]: {
            ...get(state.question.standardComments, getStandardCommentsKeyByRate(payload.rate), {}),
            [payload.id]: payload.text
          }
        }
      }
    };
  },
  [deleteQuestionStandardComment]: (state, { payload }) => {
    // * cool way to delete things
    const { [payload.id]: id, ...newRate } = {
      ...state.question.standardComments[getStandardCommentsKeyByRate(payload.rate)]
    };
    return {
      ...state,
      question: {
        ...state.question,
        standardComments: {
          ...state.question.standardComments,
          [getStandardCommentsKeyByRate(payload.rate)]: newRate
        }
      }
    };
  }
});

export default uiQuestionModalReducer;
