import { Col, Row, Select, Typography, Collapse, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import UserPreview from 'components/UserPreview';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import Icon from 'components/Icon';
import { ChevronsDown, ChevronsUp } from 'react-feather';
import moment from 'moment';
import { CollapseButton, LabelText, StyledCollapse } from 'components/CrmEntityContent/styled';
import { PERMISSIONS } from 'core/utils/constants';
import { isEqual, isEmpty, findIndex, compact, snakeCase, reduce, omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { operations } from 'redux/lists/usersListReducer';
import { clientInteractionsResource } from 'redux/resources/clientInteractions';
import { getCurrentUserPermissions, getUsersWithPermissions } from 'redux/selectors/users';
import SCard from 'components/Standard/SCard';
import { IconButton } from 'components/Buttons';
import { StyledSearch, StyledSelect, StyledCol } from 'components/PhoneCallPlayer/styled';
import RecordChainControls from './RecordChainControls';
import SText from '../../components/Standard/SText';

const { Text, Link } = Typography;

const loadUsers = ({ dispatch }) =>
  dispatch(
    operations.load({
      pagination: 'false',
      include: 'unit,role.levels',
      sort: 'last_name'
    })
  );

const CustomSelectDropdown = React.memo(({ menu, onSearch, onChange }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userLoading = useSelector(state => state.usersResource.loading);

  useEffect(() => {
    loadUsers({ dispatch });
  }, []);

  return (
    <SCard
      loading={userLoading}
      headPadding="0 12px"
      bodyPadding="0px"
      rounded="4px"
      shadowed
      width="100%"
      maxWidth="340px"
      minWidth="340px"
      title={
        <StyledSearch
          allowClear
          placeholder={t('components.recordPlayer.phoneCallInformation.searchPlaceholder')}
          onSearch={value => onSearch && onSearch(value)}
          onChange={e => onChange && onChange(e.target.value)}
        />
      }
    >
      {menu}
    </SCard>
  );
});

const { Panel } = Collapse;

const CommunicationPageHead = ({ call, clientInteractions, reviewId }) => {
  const [search, setSearch] = React.useState('');
  const users = useSelector(state => state.usersResource.byIds);
  const [open, setOpen] = useState([]);
  const [loading, setLoading] = useState(false);

  const operator = useSelector(state => state.usersResource.byIds[call?.operatorId], isEqual);
  const { loading: userLoading, failed } = useSelector(state => state.usersResource);
  const currentUserPermissions = useSelector(getCurrentUserPermissions, isEqual);
  const reviewersById = useSelector(state =>
    getUsersWithPermissions(state, { permissions: [PERMISSIONS.CAN_PERFORM_CLIENT_INTERACTION] })
  );
  const reviewers = Object.values(reviewersById).filter(({ active }) => active);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCollapse = e => {
    e.preventDefault();
    if (open.length === 0) setOpen(['1']);
    else setOpen([]);
  };

  const canChangeOperator = currentUserPermissions.includes(
    PERMISSIONS.CAN_UPDATE_CLIENT_INTERACTION_OPERATOR
  );

  const onSelectNewOperator = userId => {
    dispatch(
      clientInteractionsResource.operations.updateClientInteractionOperator({
        id: call.id,
        operatorId: userId
      })
    );
  };

  const clientInteractionsCustomFields = clientInteractions.reduce(
    (acc, { customFields }) => ({
      ...acc,
      ...reduce(customFields, (acc, val, key) => ({ ...acc, [snakeCase(key)]: val }), {})
    }),
    {}
  );

  const customFields = omit(
    {
      ...clientInteractionsCustomFields,
      ...call?.customFields
      // ...reduce(call?.customFields, (acc, val, key) => ({ ...acc, [snakeCase(key)]: val }), {})
    },
    ['sales-pipeline', 'sales-pipeline-status', 'sales_pipeline', 'sales_pipeline_status']
  );

  const addOperatorToReviewers = findIndex(reviewers, ({ id }) => id === operator?.id) === -1;

  const communicationChainCustomFields =
    call?.clientInteractionType === 'communication_chain' ? call?.customFields : null;

  // Отбрасываем кастомные поля у которых title пустой
  const filteredChainCustomFields = Object.entries(communicationChainCustomFields).reduce(
    (acc, [key, value]) => {
      if (value.title && value.title.trim() !== '') {
        acc[key] = value;
      }
      return acc;
    },
    {}
  );

  // Достаём первые 3 кастомных поля для отображения
  const firstThree = Object.fromEntries(Object.entries(filteredChainCustomFields).slice(0, 3));
  // Достаём остальные кастомные поля которые будут отображаться при раскрытии блока (Инфо)
  const restOfObjects = Object.fromEntries(Object.entries(filteredChainCustomFields).slice(3));

  const getChainNumber = () => {
    if (!call.clientPhoneNumber) {
      return '';
    }

    const string = call.clientPhoneNumber.trim();
    const currentLength = string.length; // Длина переданной строки
    const maxLength = 15; // Ограничение длины строки в 15 символов
    const substring = `${string.substring(0, maxLength)}...`; // Выводиться первые 15 символов с (...) в конце
    const stringToRender = currentLength > maxLength ? substring : string; // Строка которая пойдёт на рендер

    return (
      <>
        <SText>{t('components.recordPlayer.phoneCallInformation.callNumberChain')}</SText>
        <Tooltip title={string}>
          <SText ellipsis>{stringToRender}</SText>
        </Tooltip>
      </>
    );
  };

  const formatTime = durationParam => {
    if (durationParam === 0 || durationParam === '0') return '00:00:00';
    let duration = durationParam;

    const hours = Math.floor(duration / 3600);
    duration %= 3600;
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;

    const hourStr = hours.toString().padStart(2, '0');
    const minuteStr = minutes.toString().padStart(2, '0');
    const secondStr = seconds.toString().padStart(2, '0');

    return `${hourStr}:${minuteStr}:${secondStr}`;
  };

  const getGeneralDuration = () => {
    return (
      <>
        <Tooltip title={t('components.recordPlayer.phoneCallInformation.generalDurationTooltip')}>
          <SText>{t('components.recordPlayer.phoneCallInformation.generalDuration')}</SText>
        </Tooltip>
        <Tooltip title={formatTime(call?.duration)}>
          <SText ellipsis>{formatTime(call?.duration)}</SText>
        </Tooltip>
      </>
    );
  };

  const getCustomFieldTitle = customField => {
    if (!customField) {
      return '';
    }
    const customFieldTitle = customField?.title;
    const currentLength = customFieldTitle?.length; // Длина переданной строки
    const maxLength = 15; // Ограничение длины строки в 15 символов
    const substring = `${customFieldTitle.substring(0, maxLength)}...`; // Выводиться первые 15 символов с (...) в конце
    const stringToRender = currentLength > maxLength ? substring : customFieldTitle; // Строка которая пойдёт на рендер
    if (customField?.type === 'string_url') {
      return (
        <Tooltip title={customFieldTitle}>
          <Link href={`${customFieldTitle}`} target="_blank">
            {stringToRender}
          </Link>
        </Tooltip>
      );
    }
    return (
      <Tooltip title={customFieldTitle}>
        <SText ellipsis>{stringToRender}</SText>
      </Tooltip>
    );
  };

  return (
    <>
      <Col span={24}>
        <SCard
          bordered="1px solid #e4e6ed"
          shadowed="0px 0px 12px rgba(0, 0, 0, 0.08)"
          headPadding="8px 24px"
          bodyPadding="0px"
          loading={loading}
          marginBottom="10px"
          rounded="0 0 8px 8px"
          position="relative"
          zIndex="1"
          title={
            !loading && (
              <>
                <Row
                  type="flex"
                  align="middle"
                  justify="space-between"
                  gutter={[16, 0]}
                  style={{ position: 'absolute', zIndex: '100' }}
                >
                  <Col span={24}>
                    <Row type="flex" align="start" justify="start" gutter={[16, 0]}>
                      <SCol display="flex" paddingRight="24px !important">
                        {canChangeOperator ? (
                          <>
                            <StyledSelect
                              onClick={() => {
                                if (failed) {
                                  loadUsers({ dispatch });
                                }
                              }}
                              onChange={onSelectNewOperator}
                              defaultValue={
                                <UserPreview
                                  userId={operator?.id}
                                  user={operator}
                                  disabled
                                  showAvatar
                                  showUnit
                                />
                              }
                              value={operator?.id}
                              disabled={!canChangeOperator}
                              key={operator?.id}
                              loading={userLoading}
                              bordered={false}
                              showArrow={canChangeOperator}
                              dropdownMatchSelectWidth={false}
                              getPopupContainer={node => node.parentNode}
                              dropdownClassName="change-operator-dropdown"
                              dropdownStyle={{ padding: 0 }}
                              dropdownRender={menu => (
                                <CustomSelectDropdown
                                  menu={menu}
                                  onSearch={setSearch}
                                  onChange={setSearch}
                                />
                              )}
                            >
                              {compact([addOperatorToReviewers && operator, ...reviewers])
                                .filter(
                                  ({ name }) =>
                                    isEmpty(search) ||
                                    name?.toLowerCase()?.indexOf(search.toLowerCase()) >= 0
                                )
                                .map(({ id }) => (
                                  <Select.Option key={id} value={id}>
                                    <UserPreview
                                      userId={id}
                                      user={reviewersById[operator]}
                                      disabled
                                      showAvatar
                                      showUnit
                                    />
                                  </Select.Option>
                                ))}
                            </StyledSelect>
                            <IconButton
                              tooltip={{
                                title: t(
                                  'components.recordPlayer.phoneCallInformation.tooltipButtonInfo'
                                ),
                                overlayInnerStyle: {
                                  width: '400px'
                                }
                              }}
                              button={{
                                icon: <QuestionCircleOutlined style={{ fontSize: 16 }} />,
                                size: 'icon'
                              }}
                            />
                          </>
                        ) : (
                          <UserPreview
                            userId={operator?.id}
                            user={operator}
                            disabled
                            showAvatar
                            showUnit
                          />
                        )}
                      </SCol>
                      <StyledCol>
                        <Text default>
                          <Text default>
                            {t('components.recordPlayer.phoneCallInformation.callDateChain')}
                          </Text>
                          {moment(call.startedAt).format('DD/MM/YYYY, HH:mm')}
                        </Text>
                      </StyledCol>
                      <StyledCol>{getChainNumber()}</StyledCol>
                      <StyledCol>{getGeneralDuration()}</StyledCol>
                    </Row>
                  </Col>
                </Row>
                <RecordChainControls call={call} reviewId={reviewId} customFields={customFields} />
              </>
            )
          }
        >
          <StyledCollapse bordered={false} activeKey={1} accordion={false}>
            <Panel key="1" showArrow={false} collapsible="disabled" header="customFields">
              <SRow>
                {Object.values(firstThree).map(customField => {
                  return (
                    <SCol key={customField?.name} md={8} xs={24}>
                      {/* Название customField */}
                      <LabelText>{customField?.name}</LabelText>
                      {/* Значение customField */}
                      <Text>{getCustomFieldTitle(customField)}</Text>
                    </SCol>
                  );
                })}
              </SRow>
            </Panel>
          </StyledCollapse>
          <StyledCollapse bordered={false} activeKey={open} accordion={false}>
            <Panel key="1" showArrow={false} collapsible="disabled" header="customFields2">
              <SRow>
                {Object.values(restOfObjects).map(customField => {
                  return (
                    <SCol key={customField?.name} md={8} xs={24}>
                      {/* Название customField */}
                      <LabelText>{customField?.name}</LabelText>
                      {/* Значение customField */}
                      <Text>{getCustomFieldTitle(customField)}</Text>
                    </SCol>
                  );
                })}
              </SRow>
            </Panel>
          </StyledCollapse>
          <CollapseButton
            shape="round"
            icon={
              open.length === 0 ? (
                <Icon icon={ChevronsDown} size={14} style={{ strokeWidth: '3' }} />
              ) : (
                <Icon icon={ChevronsUp} size={14} style={{ strokeWidth: '3' }} />
              )
            }
            onClick={handleCollapse}
          >
            {t('pagesMeta.crmEntityPage.buttons.info')}
          </CollapseButton>
        </SCard>
      </Col>
    </>
  );
};

export default CommunicationPageHead;
