import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import addScopeToObjectKeys from 'core/utils/addScopeToObject';
import { usePrevious } from 'core/utils/hooks';
import { isEmpty, isEqual } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { loadTableMeta } from 'redux/ui/clientInteractions/operations';

const ClientInteractionsMeta = ({
  loadMeta,
  loading,
  loadingTables,
  tableId,
  filters,
  customFieldFilters,
  meta,
  loadingCustomFields,
  hasConflicts
}) => {
  const { t } = useTranslation();

  const allFilters = { ...filters, ...addScopeToObjectKeys('customField', customFieldFilters) };

  const prevAllFilters = usePrevious(allFilters);

  useEffect(() => {
    const shouldLoad =
      !loadingTables &&
      !loadingCustomFields &&
      tableId &&
      !isEqual(prevAllFilters, allFilters) &&
      !hasConflicts;

    if (shouldLoad) {
      loadMeta({
        options: {
          filters: isEmpty(filters?.taskAssignmentsIds)
            ? filters
            : { taskAssignmentsIds: filters?.taskAssignmentsIds },
          customFieldFilters
        }
      });
    }
  }, [allFilters]);

  return (
    <Col span={24}>
      <Row type="flex" gutter={[8, 8]} style={{ margin: '-12px -4px -4px -4px' }} align="middle">
        <Col style={{ paddingBottom: '12px' }}>
          <Typography.Text strong>
            {t('clientInteractionsPage.clientInteractionMeta.searchResult')}
          </Typography.Text>
          {loading || loadingTables ? (
            <LoadingOutlined style={{ color: '#0061D0' }} />
          ) : (
            <Typography.Text>{hasConflicts ? '' : meta?.totalCount}</Typography.Text>
          )}
        </Col>
      </Row>
    </Col>
  );
};
const mapStateToProps = state => ({
  loading: state.uiClientInteractions.loadingMeta,
  meta: state.uiClientInteractions.meta,
  filters: state.clientInteractionsList.filters,
  customFieldFilters: state.clientInteractionsList.customFieldFilters,
  loadingTables: state.communicationTablesResource.loading,
  tableId: state.uiClientInteractions.tableId,
  loadingCustomFields: state.customFieldsResource.loading
});

const mapDispatchToProps = {
  loadMeta: loadTableMeta
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientInteractionsMeta);
