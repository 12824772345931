export default {
  components: {
    colorPicker: {
      name: 'Color',
      namePlaceholder: 'Elegir un color'
    },
    rateValues: { text: { placeholder: 'Valor de texto' } },
    checklistManager: {
      autoFailModal: {
        title: '¿Desea terminar la revisión?',
        description:
          'En la lista a chequear esta activado el archivo automático. Usted puede terminar la evaluación en este momento guardando los resultados en curso o continuar la evaluación.',
        ok: 'Terminar',
        cancel: 'Continuar la evaluación'
      },
      generalCommentModal: {
        title: 'Comentario general',
        ok: 'Continuar',
        cancel: 'Cancelar'
      },
      textAreaPlaceholder: 'Comentario general',
      totalScore: 'Evaluación total:',
      question: {
        autoFailTooltip: { title: 'Se activó el archivo automático' },
        showStandardCommentSelector: { placeholder: 'Razón de la calificación' }
      },
      questionDescriptionModal: {
        modal: {
          ok: 'Continuar',
          cancel: 'Cancelar',
          alert: { title: 'Descripción' }
        }
      },
      checklistManagerHead: {
        emptyEditingChecklistDefinition: {
          title: 'Formulario de evaluación no establecido'
        },
        emptyChecklistModal: {
          title: 'Usted no eligió la lista a chequear en esta evaluación',
          description: '¿Continuar?',
          ok: 'Si',
          cancel: 'No'
        },
        allQuestionsAnswered: {
          title: 'Usted no evalúo uno o varios puntos de la lista a chequear',
          description: '¿Terminar la evaluación?',
          ok: 'Si',
          cancel: 'No'
        },
        confirmChangeChecklist: {
          title: '¿Cambiar la lista a chequear?',
          description: 'Esto eliminará la evaluación en curso',
          ok: 'Si',
          cancel: 'No'
        },
        confirmDeleteEmployee: {
          title: '¿Eliminar al usuario?',
          description:
            '¿Estás seguro/a de que deseas eliminar al usuario? Esta acción eliminará todos los datos asociados con este usuario (comunicaciones, evaluaciones, tableros).',
          ok: 'Si',
          cancel: 'No',
          errors: {
            errorTimeoutTitle: 'Eliminar usuario',
            errorTimeout:
              'Parece que tomará un poco más de tiempo. Puede continuar trabajando en el sistema, el usuario pronto será eliminado.'
          }
        },
        confirmDeleteChecklist: {
          title: 'Eliminar la evaluación?',
          description: 'La evaluación eliminada no se podrá restablecer',
          ok: 'Si',
          cancel: 'No',
          messages: {
            reviewSuccessfullyDeleted: 'Evaluación eliminada',
            deleteReviewFailed: 'No se pudo eliminar la evaluación'
          }
        },
        menu: {
          changeCurrentChecklist: 'Cambiar la lista a chequear',
          navigateToClientInteraction: 'Nueva evaluación',
          addToCalibration: 'Para la calibración',
          deleteReview: 'Eliminar la evaluación'
        },
        buttons: {
          emptyReview: 'No hay evaluación',
          submitReview: 'Terminar la evaluación',
          editReview: 'Editar evaluación',
          saveReview: 'Guardar evaluación',
          updateReview: 'Actualizar evaluación',
          inProcessReview: 'Cálculo en curso'
        },
        messages: {
          unsavedScore: 'Si va a otra página, los resultados del análisis no se guardarán.'
        }
      },
      selectChecklist: {
        emptyAdminMessage: {
          pt1:
            'No se ha configurado el formulario de evaluación para esta comunicación o departamento. Vaya a la',
          pt2: ' lista de formularios',
          pt3: '  de evaluación para establecer la configuración correcta.'
        },
        emptyUserMessage:
          'Форма оценки для данной коммуникации или отдела не настроена. Обратитесь к администратору портала для корректной установки настроек.'
      }
    },
    customRatingValuesEditor: { answer: { placeholder: 'Ingrese texto' } },
    commentList: {
      comment: {
        commentEditButton: {
          buttons: {
            edit: 'Redactar',
            answer: 'Contestar',
            delete: 'Eliminar',
            hidden: 'Oculto',
            cancel: 'Cancelar',
            save: 'Guardar'
          },
          confirmDelete: {
            title: '¿Eliminar comentario?',
            ok: 'Si',
            cancel: 'No'
          }
        },
        commentInfo: {
          hiddenCommentTooltip: 'Comentario oculto',
          answersCount: 'Respuestas:'
        },
        createReplyForm: {
          buttons: {
            hidden: 'Oculto',
            cancel: 'Cancelar',
            send: 'Enviar'
          }
        }
      }
    },
    commentsPanel: {
      buttons: {
        addComment: 'Añadir comentario',
        hidden: 'Oculto',
        cancel: 'Cancelar',
        send: 'Enviar'
      },
      commentPlaceholder: 'Comentario...'
    },
    commentsPreview: {
      leftACommentStr: 'dejé un comentario en:',
      commentPopoverTitle: 'Comentario',
      previewIcons: {
        title: 'Comentario',
        generalComment: 'Comentarios finales'
      }
    },
    timeSpecificSelector: {
      placeholder: 'Elija un período',
      otherPeriod: 'Para el periodo',
      selectButton: 'Elegir'
    },
    notifications: { logged: 'Usted accedió!' },
    recordPlayer: {
      loading: 'Cargando...',
      addToLibrary: {
        messages: {
          recordStr: 'Grabación',
          addedStr: 'añadido con exito a',
          addRecordToLibraryFailed: 'No se pudo añadir la grabación'
        },
        tooltip: 'Añadir a la biblioteca',
        modal: {
          title: 'Añadir a la biblioteca',
          ok: 'Guardar',
          cancel: 'Cancelar',
          enterFolderName: 'Elija el nombre de la carpeta:',
          folderNamePlaceholder: 'Nombre de la carpeta:',
          enterRecordName: 'Introduzca el nombre de la llamada:',
          recordNamePlaceholder: 'Nombre de la llamada'
        }
      },
      viewCustomFields: { tooltip: 'Información Adicional' },
      attachTag: { title: 'Añadir la etiqueta' },
      download: 'Descargar',
      copyLink: 'Copiar link',
      copyReview: 'Copiar el enlace de evaluación',
      copyCommunication: 'Copiar enlace comunicación',
      copyLinkSuccess: 'Enlace copiado',
      externalLink: 'Ir al sistema externo',
      recordControls: {
        play: 'Escuchar la grabación',
        backward: '-15 seg.',
        forward: '+15 seg.'
      },
      phoneCallInformation: {
        callDate: 'Fecha de llamada: ',
        callDateChain: 'Fecha de llamada: ',
        callType: 'Tipo: ',
        callNumber: 'Número: ',
        callNumberChain: 'Número: ',
        tooltipButtonInfo:
          'El operador a quien se registran la llamada y los controles de llamada. Si ya se han realizado comprobaciones en la llamada, al cambiar de operador se volverán a escribir en el nuevo operador.',
        searchPlaceholder: 'Búsqueda por operadoras',
        generalDuration: 'Duración: ',
        generalDurationTooltip: 'Duración total de la cadena de comunicación'
      }
    },
    questionModal: {
      title: 'Ajuste de la pregunta',
      buttons: {
        addComment: 'Añadir comentario',
        save: 'Guardar',
        update: 'Actualizar',
        cancel: 'Cancelar'
      },
      form: {
        fields: {
          namePlaceholder: 'Nombre de la pregunta',
          nameInfo:
            'El nombre de la pregunta (punto de evaluación) se reflejará en la lista a chequear.',
          name: 'Nombre',
          description: 'Descripción',
          descriptionPlaceholder: 'Descripción de la pregunta',
          descriptionInfo:
            'Describa, que competencias, conocimientos y hábilidades usted quiere evaluar con la ayuda de esta pregunta (punto de evaluación) de la lista a chequear. Esta descripción le ayudará al elegir las preguntas de la lista de preguntas, para formar una lista de evaluación completa.',
          scaleType: 'Escala de evaluación',
          scaleTypeInfo:
            'Elija una escala lista para la evaluación de esta pregunta o en "Escala arbitraria" señae la evaluación que quiere utilizar para evaluar a los administradores en estas pregunta.',
          
          questionType: 'Tipo de criterio',
          questionTypeInfo: 'Elija el tipo de criterio',
          
          valuesDisplayType: 'Reflejado en la lista a chequear',
          valuesDisplayTypeInfo:
            'Forma en que se mostrará la escala en la lista a chequear durante la evaluación. En la analítica los resultados tendrán resultados numéricos.',
          defaultRatingValueEnabled: 'Evaluación predeterminada en la lista a chequear',
          defaultRatingValueEnabledInfo:
            'Al inicio de la evaluación de la comunicación, usted puede señalar que puntuación se asignará automáticamente a esta evaluación.',
          autofailEnabled: 'Archivo automático de la lista a chequear',
          autofailEnabledInfo:
            'Al evaluar según este criterio, la puntuación final será la mínima de la evaluada.',
          autofailCriteriesAlert: 'Personaliza tus criterios',
          autofailTotalScore: 'Resumen del formulario de evaluación:',
          questionGroupAutofail: 'Autoarchivo grupal',
          questionGroupAutofailCriteriesAlert: 'Personaliza tus criterios',
          questionGroupAutofailTotalScore: 'Resumen de grupo:',
          autofailEnabledAlert: {
            title: 'Error automático',
            description:
              'Especifique una puntuación o varios puntos para este criterio, cuando la nota final de todas las formas sea igual al valor mínimo posible.'
          },
          standardCommentsEnabled: 'Razón de la calificación',
          standardCommentsEnabledInfo:
            'Escriba los comentarios que agrega con más frecuencia al otorgar una puntuación específica en este criterio.'
        },
        messages: { enterName: 'Por favor, introduzca el nombre' },
        questionAnswers: 'Criterios:'
      }
    },
    registerFromIntegration: {},
    textCommunicationContent: {
      conversationInfo: {
        date: 'Fecha: ',
        communicationId: 'Sala de Chat: '
      },
      conversationPart: {
        client: 'Cliente',
        operator: 'Operador',
        buttons: {
          comment: 'Comentar en',
          viewComments: 'Ver comentarios',
          addComment: 'Añadir comentario'
        },
        emptyComments: 'Sin comentarios aún'
      }
    },
    userPreview: {
      userNotFound: 'No se encuentra el usuario',
      userNotInstalled: 'Usuario no instalado'
    },
    addToCalibrationModal: {
      messages: {
        fileSuccessfullyAddedToGroup: 'Archivo agregado al grupo',
        addFileToGroupFailed: 'No se pudo agregar el archivo al grupo',
        communicationGroupSuccessfullyCreated: 'Grupo de comunicación creado',
        communicationGroupCreateFailed: 'No se pudo crear el grupo'
      },
      title: 'Guardar estimación para calibración',
      ok: 'Continuar',
      cancel: 'Cancelar',
      form: {
        fileName: 'Nombre del archivo:',
        callName: 'Nombre de la llamada',
        folderName: 'Elija el nombre de la carpeta:',
        folderNamePlaceholder: 'Nombre del grupo de comunicación',
        buttons: { addGroup: 'Añadir grupo' }
      }
    },
    calibrationSessionStatusTag: { unistalledTag: 'No instalado' },
    checklistDefinitionStatusTag: { unistalledTag: 'No instalado' },
    checklistHistoryModal: {
      loading: 'Recordando la dinámica... 🙄',
      empty: 'Sin datos para el período seleccionado 😕'
    },
    checklistsDefinitionsSelect: { allChecklists: 'Todas las formas de evaluación' },
    checklistsList: {
      messages: {
        copySuccessfullyCreated: 'Copia creada',
        createCopyFailed: 'No se logró crear la copia',
        sumPercentageLessThanHundren:
          'La suma de las preguntas en la lista a chequear debe alcanzar el 100%',
        emptyQuestions:
          'Para la publicación, los criterios deben agregarse al formulario de evaluación'
      },
      publishChecklistConfirm: {
        title: '¿Publicar la lista a chequear?',
        description:
          'No se puede redactar la lista a chequear luego de publicarla: método y cálculo de la evaluación, escala de evalación y cantidad de preguntas. Antes de publicar, revise que todos los ajustes son correctos.',
        ok: 'Continuar',
        cancel: 'Cancelar'
      },
      confirmChecklistArchive: {
        title: '¿Guardar la lista a chequear en el archivo?',
        description:
          'Las listas a chequear archivadas no están disponibles para ser elegidas como listas a chequear para evaluar',
        ok: 'Ir al archivo',
        cancel: 'Cancelar'
      },
      confirmChecklistDelete: {
        title: '¿Está seguro de que desea eliminar el formulario de calificación?',
        description: 'Esta acción es irreversible',
        ok: 'Eliminar',
        cancel: 'Cancelación'
      },
      checklistQuestionsStr: ' criterios',
      menu: {
        publish: 'Publicar',
        edit: 'Personalización',
        view: 'Ver',
        settings: 'Ajustes de la lista a chequear',
        copy: 'Crear una copia',
        archive: 'Guardar en el archivo',
        delete: 'Eliminar la lista a chequear'
      },
      listColumns: {
        name: 'Nombre',
        description: 'Descripción',
        createdAt: 'Creado por',
        author: 'Autor',
        status: 'Estatus',
        actions: 'Acción'
      },
      loading: 'Cargando los formularios de evaluación... 😤',
      listLoaded: 'Es todo 😊'
    },
    colorZones: {
      list: {
        name: 'Nombre',
        color: 'Color',
        description: 'Descripción',
        range: 'Rango de calificaciones'
      },
      scores: {
        high: 'Exelente',
        medium: 'Normal',
        low: 'Crítico'
      }
    },
    colorZonesBeforePublishModal: {
      title: 'Ajeste de la zona de evaluación',
      ok: 'Publicar',
      cancel: 'Cancelación'
    },
    commentsModal: {
      title: 'Comentario',
      empty: 'Sin comentarios aún'
    },
    durationPicker: {
      from: 'Desde',
      to: 'Hasta'
    },
    questionDetailsModal: {
      ok: 'Continuar',
      cancel: 'Cancelar',
      loading: 'Recordando la dinámica... 🙄 ',
      empty: 'Sin datos para el período seleccionado 😕'
    },
    questionsList: {
      messages: {
        blockedDeleteQuestion:
          'No puede eliminar un criterio agregado a una calificación publicada o archivada'
      },
      confirmDelete: {
        title: '¿Está seguro de que desea eliminar un criterio?',
        description: 'Esta acción es irreversible',
        ok: 'Eliminar',
        cancel: 'Cancelar'
      },
      menu: {
        edit: 'Redactar la pregunta',
        copy: 'Crear una copia',
        delete: 'Eliminar la pregunta'
      },
      isNotAddedToChecklist: 'No agregado a los formularios de evaluación',
      and: 'y más',
      listColumns: {
        name: 'Nombre',
        author: 'Autor',
        createdAt: 'Creado por',
        description: 'Descripción',
        isAddedToChecklist: 'Lista a chequear',
        actions: 'Comportamiento'
      },
      loading: 'Cargando criterios... 😤',
      listLoaded: 'Es todo 😊'
    },
    rangeInputs: {
      from: 'Desde',
      to: 'Hasta'
    },
    communicationInfoModal: {
      title: 'Información Adicional',
      chain: 'Cadena de llamada',
      ok: 'Continuar',
      emptyFieldDescription:
        'No se pudo encontrar la descripción del campo. Para evitar errores, describa la configuración de todos los campos personalizados.'
    },
    statusSelector: {
      selectStatus: 'Estatus del contacto',
      newStatusNamePlaceholder: 'Nuevo estado',
      resetStatus: 'Reestablezca su estatus',
      addStatus: 'Añadir',
      blankNameError: `El nombre del estado no puede estar vacío`
    },
    timestamp: 'Hora:',
    appealHead: {
      sendAppeal: 'Envíe una apelación',
      cancelAppeal: 'Cancelar la apelación',
      closeAppeal: 'Terminar de procesar',
      editAppeal: 'Editar procesamiento',
      createAppeal: 'Presentar una apelación'
    },
    createTask: 'Establecer una tarea',
    addUsersModal: 'Empleados',
    selectUsers: {
      admin: 'Administrador',
      selectUsersTooltip1: 'Usuario con este correo electrónico',
      selectUsersTooltip2:
        'ya registrado en el sistema. Puedes cambiar su correo electrónico y activarlo en la configuración de "Equipos"'
    },
    syncBackgroundJob: {
      waitText: 'Esto puede tardar unos minutos.',
      waitTextDescription: 'Puede esperar o comenzar a usar la aplicación de inmediato'
    },
    syncData: { selectSyncPeriod: 'Elija un poeríodo de sincronización' }
  }
};
