import { Col, Radio, Row, Tooltip } from 'antd';
import { WIDGET_VIEW_MODES } from 'core/utils/constants';
import { get, sum, isEqual } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import { useTranslation } from 'react-i18next';
import SCol from 'components/Standard/SCol';
import { TimePeriod } from 'components/TimePeriod';
import { WidgetTitle } from 'components/WidgetTitle';
import SRow from 'components/Standard/SRow';
import EmptyWidget from '../../Components/EmptyWidget';
import SettingsModal from '../../Components/SettingsModal';
import { LargeWidgetCard } from '../../styled';
import WidgetCardDivider from '../../Components/WidgetCardDivider';
import { widgetInfoTextSetter, widgetNameSetter, widgetPeriodSetter } from '../../utils';
import CommentsTable from './Components/CommentsTable';
import PieChart from './Components/PieChart';

const FlagsAndComments = ({ widgetData, loadingAnalytics, loadingWidgetId, isWarningWidget }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id, type, filters, name } = widgetData;

  const widgetValue = useSelector(state => state.dashboardAnalytics.byIds[id], isEqual);

  const viewMode = get(filters, 'viewMode', WIDGET_VIEW_MODES.OPERATORS.value);

  const ratingFlagsCount = get(widgetValue, 'rating_flags_count', []);
  const ratingFlagsCountByUnits = get(widgetValue, 'rating_flags_count_by_units', []);
  const ratingFlagsCountByOperators = get(widgetValue, 'rating_flags_count_by_operators', []);

  const tableData =
    viewMode === WIDGET_VIEW_MODES.OPERATORS.value
      ? ratingFlagsCountByOperators
      : ratingFlagsCountByUnits;

  const pieData = ratingFlagsCount;

  const isEmptyComments = sum(Object.values(ratingFlagsCount)) < 1;

  return (
    <>
      {loadingWidgetId === id || isEmptyComments || isWarningWidget ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingAnalytics={loadingAnalytics}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ) : (
        <Col span={24} key={id}>
          <LargeWidgetCard>
            <SRow type="flex" justify="space-between" align="top">
              <SCol span={8}>
                <SRow type="flex" gutter={[6, 0]} justify="start" alignItems="center" wrap={false}>
                  <SCol flex="auto" display="flex" alignItems="center">
                    <Tooltip title={widgetNameSetter({ name, type })}>
                      <WidgetTitle>{widgetNameSetter({ name, type })}</WidgetTitle>
                    </Tooltip>
                    {widgetInfoTextSetter({ type })}
                  </SCol>
                </SRow>

                <TimePeriod type="secondary">
                  {widgetPeriodSetter({
                    widgetsFilters: filters,
                    widgetType: type,
                    showDateMode: true
                  })}
                </TimePeriod>
              </SCol>
              <Col span={6}>
                <Row justify="center">
                  <Col>
                    <Radio.Group
                      onChange={e =>
                        dispatch(
                          updateDashboardWidget({
                            id,
                            type,
                            filters: { ...filters, viewMode: e.target.value }
                          })
                        )
                      }
                      value={viewMode}
                    >
                      <Radio.Button value={WIDGET_VIEW_MODES.OPERATORS.value}>
                        {t(WIDGET_VIEW_MODES.OPERATORS.title)}
                      </Radio.Button>
                      <Radio.Button value={WIDGET_VIEW_MODES.UNITS.value}>
                        {t(WIDGET_VIEW_MODES.UNITS.title)}
                      </Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>
              </Col>
              <SCol display="flex" justifyContent="flex-end" alignItems="center" span={8}>
                <SettingsModal widgetData={widgetData} />
              </SCol>
            </SRow>

            <Row type="flex" justify="space-between">
              <Col span={9} display="flex" alignItems="center">
                <PieChart pieData={pieData} viewMode={viewMode} filters={filters} />
              </Col>
              <Col span={1} display="flex" alignItems="center">
                <WidgetCardDivider />
              </Col>
              <Col span={14} display="flex" alignItems="center">
                <CommentsTable tableData={tableData} viewMode={viewMode} filters={filters} />
              </Col>
            </Row>
          </LargeWidgetCard>
        </Col>
      )}
    </>
  );
};

export default FlagsAndComments;
