export default {
  clientInteractionPage: {
    review: 'Проверка: ',
    messages: {
      emptyQuestionsComments:
        'Нельзя сохранить пустую проверку. Добавьте комментарии к звонку или оцените хотя бы один критерий',
      emptyQuestionsValues:
        'Нельзя сохранить только комментарии к критериям. Добавьте оценку одному или нескольким критериям',
      needToChangeEditMode: 'Чтобы выполнить действие, перейдите в режим редактирования оценки',
      commentSuccessfullyUpdated: 'Комментарий обновлён',
      updateCommentFailed: 'Не удалось обновить комментарий',
      closeDrawer: {
        title: 'Закрыть оценку?',
        content: 'При закрытии результаты проверки не будут сохранены.'
      }
    },
    alerts: {
      disabledUser: {
        title: 'Эммм… данный пользователь не активен 😢',
        description: 'Для перехода к оценке, сделайте пользователя активным.',
        button: 'Перейти в настройки'
      }
    }
  }
};
